<script>
/* eslint-disable */
import axios from 'axios';

import DropZone from '@/components/widgets/dropZone';
import {ref} from 'vue';

import Multiselect from '@vueform/multiselect';

import {required, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Add-product component
 */
export default {
  page: {
    title: 'Add Product',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const dropzoneFile = ref('');
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector('.dropzoneFile').files[0];
    };
    return {
      dropzoneFile, drop, selectedFile, v$: useVuelidate(),
    };
  },
  components: {
    DropZone,
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'Add Product',
      items: [
        {
          text: 'Ecommerce',
          href: '/',
        },
        {
          text: 'Add Product',
          active: true,
        },
      ],
      value: null,
      value1: null,
      product: {
        name: '',
        manufacture_name: '',
        manufacture_brand: '',
        price: null,
      },
      formData: new FormData(),
      submitted: false,
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
      image: '',
      file: '',
    };
  },
  validations: {
    product: {
      name: {
        required: helpers.withMessage('Name is required', required),
      },
      manufacture_name: {
        required: helpers.withMessage('manufacture name is required', required),
      },
      manufacture_brand: {
        required: helpers.withMessage(
          'Manufacture brand is required',
          required,
        ),
      },
      price: {
        required: helpers.withMessage('Price is required', required),
      },
    },
  },
  methods: {
    onAccept(file) {
      this.image = file.name;
      this.file = file;
    },
    productAdd() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {

      } else {
        const formData = new FormData();
        formData.append('name', this.product.name);
        formData.append('manufacture_name', this.product.manufacture_name);
        formData.append('manufacture_brand', this.product.manufacture_brand);
        formData.append('price', this.product.price);
        formData.append('image', this.file, this.image);

        //* * Add product in api using post method *//
        axios.post('http://localhost:8000/api/products', formData).
          then((res) => res).
          catch((err) => err);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic Information</h4>
            <p class="card-title-desc">Fill all information below</p>

            <form @submit.prevent="productAdd">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="productname">Product Name</label>
                    <input
                      id="productname"
                      v-model="product.name"
                      :class="{
                        'is-invalid': submitted && v$.product.name.$error,
                      }"
                      class="form-control"
                      name="name"
                      type="text"
                    />
                    <div
                      v-if="submitted && v$.product.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.name.required.$message">{{
                          v$.product.name.required.$message
                        }}</span>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="manufacturername">Manufacturer Name</label>
                    <input
                      id="manufacturername"
                      v-model="product.manufacture_name"
                      :class="{
                        'is-invalid':
                          submitted && v$.product.manufacture_name.$error,
                      }"
                      class="form-control"
                      name="manufacture_name"
                      type="text"
                    />
                    <div
                      v-if="submitted && v$.product.manufacture_name.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="v$.product.manufacture_name.required.$message"
                      >{{
                          v$.product.manufacture_name.required.$message
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="manufacturerbrand">Manufacturer Brand</label>
                    <input
                      id="manufacturerbrand"
                      v-model="product.manufacture_brand"
                      :class="{
                        'is-invalid':
                          submitted && v$.product.manufacture_brand.$error,
                      }"
                      class="form-control"
                      name="manufacture_brand"
                      type="text"
                    />
                    <div
                      v-if="submitted && v$.product.manufacture_brand.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="v$.product.manufacture_brand.required.$message"
                      >{{
                          v$.product.manufacture_brand.required.$message
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="price">Price</label>
                    <input
                      id="price"
                      v-model="product.price"
                      :class="{
                        'is-invalid': submitted && v$.product.price.$error,
                      }"
                      class="form-control"
                      name="price"
                      type="text"
                    />
                    <div
                      v-if="submitted && v$.product.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.product.price.required.$message">{{
                          v$.product.price.required.$message
                        }}</span>
                    </div>
                  </div>
                  <label>Product Images</label>
                  <DropZone @change="selectedFile" @drop.prevent="drop"/>
                  <span class="file-info">File: {{ dropzoneFile.name }}</span>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3">
                    <label class="control-label">Category</label>
                    <multiselect
                      v-model="value"
                      :options="options"
                    ></multiselect>
                  </div>
                  <div class="mb-3">
                    <label class="control-label">Features</label>
                    <multiselect
                      v-model="value1"
                      :multiple="true"
                      :options="options"
                    ></multiselect>
                  </div>
                  <div class="mb-3">
                    <label for="productdesc">Product Description</label>
                    <textarea
                      id="productdesc"
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-primary me-1" type="submit">
                  Save Changes
                </button>
                <button class="btn btn-secondary" type="submit">Cancel</button>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Meta Data</h4>
            <p class="card-title-desc">Fill all information below</p>

            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="metatitle">Meta title</label>
                    <input
                      id="metatitle"
                      class="form-control"
                      name="productname"
                      type="text"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="metakeywords">Meta Keywords</label>
                    <input
                      id="metakeywords"
                      class="form-control"
                      name="manufacturername"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="metadescription">Meta Description</label>
                    <textarea
                      id="metadescription"
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button class="btn btn-primary me-1" type="submit">
                Save Changes
              </button>
              <button class="btn btn-secondary" type="submit">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
